import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      themes: {
        light: {
          primary: "#4AC6A0",
          secondary: "#118C66",
          accent: "#025F59",
          error: "#F44336",
          warning: "#707070",
          info: "#7A7A53",
          success: "#795548",
          my_white: "#FFFFFF",
          my_gray: "#707070",
          my_background: "#CBEFE9"
        },
      },
    },
});
