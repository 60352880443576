import Vue from 'vue'
import axios from 'axios'

// デフォルトステート
const defaultState = () => {
  return {
    // 集計結果オブジェクト
    aggregateResult: null,
    // 集計結果pdfデータ
    resultPdf: null,
    // 集計結果pdf生成のステート値
    isResultPdfGenerated: false,
  }
}

export default {
  namespaced: true,

  state: defaultState(),

  getters: {
    // 物件概要の取得
    getBuildingInfo(state){
      return state.aggregateResult.general.buildingInfo
    },
    // 集計結果pdfデータ
    getResultPdf(state){
      return state.resultPdf
    },
    // 集計結果pdf生成のステート値
    getIsResultPdfGenerated(state){
      return state.isResultPdfGenerated
    },
    // 建築物エネルギー消費性能基準および建築物エネルギー消費性能誘導基準の結果
    getAggregateResults(state) {
      return state.aggregateResult
    }
  },

  mutations: {
    // stateのリセット
    reset(state, value){
        state = Object.assign(state, value)
    },
    // stateの更新
    update(state, values){
        Object.keys(values).forEach(key => {
            Vue.set(state, key, values[key])
        })
    },
  },

  actions: {
    // stateのリセットを呼び出す
    reset({commit}){
        commit('reset', defaultState())
    },
    // stateの更新を呼び出す
    update({commit}, values){
        commit('update', values)
    },
    // 集計結果の取得
    async getAggregateResult({commit, rootGetters}) {
      const householdJobId = rootGetters['fileupload/getHouseholdJobId']
      const commonAreaJobId = rootGetters['fileupload/getCommonAreaJobId']
      const householdsList = rootGetters['fileupload/getHouseholdsList']
      const basicInfo = rootGetters['basicInfo/getBasicInfo']

      const request_json = {
        householdJobId: householdJobId,
        commonJobId: commonAreaJobId,
        basicInfo: basicInfo,
        householdsList: householdsList
      }

      return axios.post('/api/aggregate', request_json)
        .then(response => {
          commit('update', {aggregateResult:response.data['aggregate_result']})
          return "success"
        }).catch(() => {
            return "error"
        })
    },
    // 集計結果pdfデータの取得
    async generateResultPdf({dispatch, commit, rootGetters}) {
      const householdJobId = rootGetters['fileupload/getHouseholdJobId']
      const commonAreaJobId = rootGetters['fileupload/getCommonAreaJobId']

      const request_json = {
        householdJobId: householdJobId,
        commonAreaJobId: commonAreaJobId
      }
      return axios.post('/api/pdf', request_json, {responseType: 'arraybuffer'})
        .then(response => {
          commit('update', {resultPdf:response.data})
        }).catch(() => {
          dispatch('setError', 'エラーが発生しました。しばらくしてからもう一度お試し下さい。', { root: true })
      })
    },
  }
}
