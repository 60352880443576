import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    // 利用規約画面
    path: '/',
    name: 'term',
    component: () => import('@/views/Terms.vue'),
    meta: { page: 'term' }
  },
  {
    // 基本情報画面
    path: '/basic-info',
    name: 'basic-info',
    component: () => import('@/views/BasicInfo.vue'),
    meta: { page: 'calc' }
  },
  {
    // 住戸部分の計算結果PDFのアップロード画面
    path: '/household-upload',
    name: 'household-upload',
    component: () => import('@/views/HouseholdCalcResultUploader.vue'),
    meta: { page: 'calc' }
  },
  {
    // 共用部の計算結果PDFのアップロード画面
    path: '/common-area-upload',
    name: 'common-area-upload',
    component: () => import('@/views/CommonAreaCalcResultUploader.vue'),
    meta: { page: 'calc' }
  },
  {
    // 集計結果と各戸集計画面
    path: '/total-result',
    name: 'total-result',
    component: () => import('@/views/TotalResult.vue'),
    meta: { page: 'calc' }
  },
  {
    // PDF生成画面
    path: '/pdf-generate',
    name: 'pdf-generate',
    component: () => import('@/views/PdfGenerate.vue'),
    meta: { page: 'calc' }
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  // バージョン番号の取得
  await store.dispatch('getVersion')

  // 入力内容のリセット
  if(to.meta.page == 'term') store.dispatch('reset')
  if(to.path != '/' && ( from.matched.length == 0 || to.matched.length == 0) ) {
      next({ path: '/' })
  }
  next()
})
export default router
