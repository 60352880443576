<template>
  <v-app my_background>
    <v-app-bar height="90" app flat color="primary" class="pa-0 my_white--text">
      <v-container fruid class="mx-0 bl_header_titleWrapper">
        <v-row justify="center" class="fill-height">
            <v-col cols="10" class="py-0 d-flex align-center">
              <v-app-bar-nav-icon class="el_icon__mainBuild">
                <svg version="1.1" id="el_iconBuild" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                y="0px" viewBox="0 0 131.8 109.1" style="enable-background:new 0 0 131.8 109.1;" xml:space="preserve">
                <path class="st0" d="M48.6,38.8h-9.7v-8.4h9.8L48.6,38.8z
                M48.6,14.1h-9.7v8.4h9.8L48.6,14.1z M31,14.1h-9.8v8.4H31V14.1z M66.3,30.4h-9.8v8.4h9.8V30.4z M66.3,46.5h-9.8v8.4h9.8V46.5z
                M48.6,46.5h-9.7v8.4h9.8L48.6,46.5z M66.3,14.1h-9.8v8.4h9.8V14.1z M31,30.4h-9.8v8.4H31L31,30.4z M31,46.5h-9.8v8.4H31L31,46.5z
                M88.8,86.2H0V71.7h6.7v-3.6H0V53.8h6.7v-3.6H0V35.9h6.7v-3.6H0V17.9h6.7v-3.6H0V0h83.7l30,14.3v14.5c-0.9-0.1-1.7-0.2-2.6-0.3H110
                v-12L83.7,3.7v78.8h5.2c-0.1,1-0.2,2-0.2,3v0.7H88.8z M79.9,3.7H3.7v7h6.7v11H3.7v7h6.7v11H3.7v7h6.7v11H3.7v7h6.7v11H3.7v7h17.5
                V64.9h13v17.5h3.6V64.9h13v17.5h3.6V64.9h13v17.5h12.5V3.7H79.9z M131.8,85.4v23.7h-3.7V85.4c0-4.9-2.1-9.5-5.8-12.7l-8.8,19.1
                l-4.1-0.1l-9-19.4c-3.9,3.2-6.2,8-6.2,13.1v23.7h-3.7V85.4c0-10.6,8-19.4,18.5-20.5c-6.7-1.1-11.6-6.8-11.6-13.6v-7.2
                c0-7.6,6.2-13.8,13.8-13.8s13.8,6.2,13.8,13.8v7.2c0,6.8-4.9,12.5-11.6,13.6C124,66.2,131.8,75,131.8,85.4z M111.2,61.4
                c5.6,0,10.1-4.5,10.1-10.1v-7.2c0-5.6-4.5-10.1-10.1-10.1c-5.6,0-10.1,4.5-10.1,10.1c0,0,0,0,0,0v7.2
                C101.1,56.8,105.6,61.3,111.2,61.4C111.2,61.4,111.2,61.4,111.2,61.4L111.2,61.4z M116.4,76.7h-9.9l4.9,10.6L116.4,76.7z
                M119.3,70.6c-0.5-0.2-1.1-0.5-1.6-0.7c-2-0.9-4.2-1.4-6.4-1.2c-2.7,0-5.3,0.6-7.7,1.9l1.2,2.7H118L119.3,70.6z"/>
                </svg>
              </v-app-bar-nav-icon>
              <v-app-bar-title>
                  <div class="text-h7 white--text" style="font-size:14px;">エネルギー消費性能計算プログラム</div>
                  <div>
                      <span class="text-h5 white--text">共同住宅等の計算結果集計プログラム</span>
                      <span class="version">{{ version }}</span>
                  </div>
              </v-app-bar-title>
            </v-col>
        </v-row>
      </v-container>
      <template v-slot:extension v-if ="$route.name != 'term'">
          <app-menu/>
      </template>
    </v-app-bar>
    <v-main>
        <v-container fluid class="fill-height my_background lighten-1 text--darken-1 grey--text">
            <v-row justify="center" class="fill-height">
                <v-col cols="10" class="white py-8 px-4 px-md-12">
                    <router-view/>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppMenu from '@/components/AppMenu'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'App',
  components: {
    AppMenu
  },
  computed: {
    ...mapGetters({version:'getVersion'})
  },
  watch:{
    version: function (newVal, oldVal) {
      if(newVal != oldVal) {
        location.reload(true)
      }
    }
  },
  methods:{
    ...mapActions(['getVersion'])
  }

}
</script>
<style>
.v-toolbar__extension {
    padding: 0px !important;
}
.version {
    display: inline-block;
    margin: 0 0 0 16px;
    padding: 4px 12px;
    font-size: 10px;
    font-weight: bolder;
    color: #fff;
    background-color: #025f59;
    border-radius: 3px;
}
</style>
