import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import axios from 'axios'
import basicInfo from '@/store/modules/basicInfo'
import fileupload from '@/store/modules/fileupload'
import result from '@/store/modules/result'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        //バージョン番号
        version: '',
        // エラーリスト
        error: []
    },

    modules: {
        basicInfo,
        fileupload,
        result
    },

    getters: {
        // プログラムのバージョン番号
        getVersion(state){
        return state.version
        },
        // エラーリスト
        error(state) {
            return state.error
        },
    },

    mutations: {
        // stateのアペンド
        append(state, values){
            Object.keys(values).forEach(key => {
                Vue.set(state, key, state[key].concat(values[key]))
            })
        },
        // stateの更新
        update(state, values){
            Object.keys(values).forEach(key => {
                Vue.set(state, key, values[key])
            })
        },
    },

    actions: {
        // stateのリセットを呼び出す
        reset({dispatch, commit}){
            commit('update', {error : []})
            dispatch('basicInfo/reset')
            dispatch('fileupload/reset')
            dispatch('result/reset')
        },
        // エラーメッセージの追加
        addError({commit}, errorMessage){
            commit('append', {error: [errorMessage]})
        },
        // 唯一のエラーメッセージを設定
        setError({commit}, errorMessage){
            commit('update', {error: [errorMessage]})
        },
        // エラーをリセット
        clearError({commit}){
            commit('update', {error: []})
        },

        //-------------------------------------------------------
        //            APIの処理
        //-------------------------------------------------------

        // バージョン番号の取得
        async getVersion({commit}) {
            return axios.get('/api/version') 
            .then(response => {
            commit('update', {version: response.data.version})
        })
        }
    },
    plugins: [
        createPersistedState({storage: window.sessionStorage})
    ]
})
